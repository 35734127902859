import { Link } from "react-router-dom";
import Logo from "../assets/images/app-logo.svg";
import MobileMenu from "./mobileNav";

export const Header = () => {
    return (
        <nav className="bg-[#fff] py-2 bg-rd-500 fixed top-0 w-full h-max z-50 shadow-sm md:shadow-0">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-2 flex  items-center justify-between flex-shrink-0 text-white">
                <div>
                    <Link to={"/"}>
                        <img src={Logo} alt="" className="w-[50px] md:w-[80px]" />
                    </Link>
                </div>

                <div className="hidden lg:flex text-[#292D32] flex gap-10 items-center">
                    <a href="/" className="text-sm font-[500] text-[#262626]">Home</a>
                    <a href="/about" className="text-sm font-[500] text-[#262626]">About us</a>
                    <a href="/services" className="text-sm font-[500] text-[#262626]"> Services </a>
                    <a href="/clinicians" className="text-sm font-[500] text-[#262626]">Clinicians</a>
                    <a href="/insurance" className="text-sm font-[500] text-[#262626]">Insurance Accepted</a>
                    <a href="/contact" className="text-sm font-[500] text-[#262626]">Contact</a>
                </div>

                <div className="opacity-0">
                    <div className="hidden lg:flex text-[#292D32] flex gap-10 items-center">
                        <a className="bg-[#29ABE2] rounded-[16px] px-8 py-3 text-white text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Book Appointment</a>
                    </div>

                </div>

                <MobileMenu />
            </div>
        </nav>
    )
}