import { Link } from "react-router-dom";
import PlayImg from "../assets/images/play.png";
import Elipses from "../assets/images/elipses.png";
import BG from "../assets/images/bg.png";


export const Approach = () => {
    return (
        <>
            <div className="hidden bg-[#FDFAF3] lg:py-20 min-h-[400px] md:min-h-[90svh] flex items-center justify-center" id="approach" style={{ backgroundColor: 'rgba(0, 0, 0, 0.70)' }}>
                <div className="mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full flex items-center justify-center">

                    <div className="l">
                        <h2 className="text-2xl md:text-3xl font-semibold text-[#fff] text-center mb-3">
                            #Our Story
                        </h2>


                        <img src={PlayImg} alt="play" className="w-[40%] mx-auto" />

                    </div>
                    <div>

                    </div>
                </div>
            </div>


            <div id="choose" className="hidden mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full relative">

                <div className="absolute left-0 w-full mt-4">
                    <img src={BG} alt="play" className="w-[30%] mx-auto" />
                </div>

                <div className="text-center mt-5 md:mt-20 pt-14">
                    <div className="w-fit mx-auto bg-[#DCDCDC33] text-md font-[600] p-3 px-5 rounded-2xl">
                        Why Choose Us
                    </div>
                    <h5 className="text-md lg:text-xl font-semibold mt-5"> Reasons Why You Should Choose Angel Of Royalty Home Care</h5>
                </div>

            </div>

            <div className="mx-auto hidden w-full max-w-screen-xl p-4 md:pb-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-10">
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Onsite House Manager</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our house manager stays in the home to ensure rules are observed.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Re-Creation Center</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our recreation center is well furnished and equipped recreation room to keep you busy.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Fully Furnished</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Angel Of Royalty Home Care is 100% furnished with the modern interior to suite everyone</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">24/7 Surveillance</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Home Care is under 24/7 surveillance making our home safe and secure.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Wi-Fi Services</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Care home  has 24/7 wi-fi services  installed for you to browse the internet.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Weekly Group Events</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our weekly group events consists of movie nights, picnics, arts and crafts and pizza party.</p>
                </div>


            </div>


            <div className="w-full md:w-[80%] mx-auto">
                <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.1153029605066!2d-95.51744672452166!3d29.71641913403819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c254736e1e5f%3A0x174b108b814cf180!2s7457%20Harwin%20Dr.%20%23310%2C%20Houston%2C%20TX%2077036!5e0!3m2!1sen!2sus!4v1712022014604!5m2!1sen!2sus" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

        </>
    )
}