import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Insurance from "./pages/insurance";
import Clinicians from "./pages/clinicians";
import AboutPage from "./pages/about";
import ServicesPage from "./pages/services";
import ContactPage from "./pages/contact";





const baseRoutes = [
  {
    path: "/",
    element: <Home />,
    title: "Home"
  },
  {
    path: "/about",
    element: <AboutPage />,
    title: "About"
  },
  {
    path: "/services",
    element: <ServicesPage />,
    title: "Services"
  },
  {
    path: "/contact",
    element: <ContactPage />,
    title: "Contact"
  },
  {
    path: "/insurance",
    element: <Insurance />,
    title: "Insurance"
  },
  {
    path: "/clinicians",
    element: <Clinicians />,
    title: "Clinicians"
  },

]
const AppRoutes = () => {

  const pageRoutes = baseRoutes.map(({ path, title, element }) => {
    return <Route key={title} path={`/${path}`} element={element} />;
  });



  return (
    <BrowserRouter>

      <Routes>{pageRoutes}</Routes>
    </BrowserRouter>
  )
};

export default AppRoutes
