import { Link } from "react-router-dom";
import HeroImg from "../assets/images/hero-3.png";
import users from "../assets/images/users.svg";

export const Hero = () => {
    return (
        <>
            <div class="hidden md:h-[160vh] text-white text-center grid bg-inherit bg-no-repeat bg-img-home">
                <div class="col-start-1 row-start-1 bg-gray-800 bg-opacity-70 w-full h-full"></div>
                <div class="col-start-1 row-start-1 mx-auto my-auto">
                    <h1 class="font-bold text-2xl">Hero Message</h1>
                    <p>Something interesting about hero message</p>
                </div>
            </div>
            <nav className="home-hero relative top-[50px] pt-12 pb-5 lg:pt-0 md:top-[80px] lg:top-[40px] lg:pb-10 lg:pt-32  md:h-[50svh] lg:h-[80svh] bg-cover md:bg-cover" id="home">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex flex-col lg:flex-col items-center gap-4 md:gap-10 justify-center flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-[1/2]">
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-3">
                            EXPERT GUIDANCE FOR A FULFILLING LIFE

                        </h2>

                        <p className="my-4 text-md lg:w-[80%] mx-auto text-[#fff] text-center lg:text-center font-[500]">
                            Need Expert guidance to navigate life’s challenges and Empower Personal Growth? We have got you covered.
                        </p>

                        <div className="my-8 flex items-center justify-center lg:justify-center gap-">
                            <a className="bg-[#29ABE2] rounded-[16px] px-8 py-4 text-white text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Book Appointment</a>

                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </nav></>

    )
}