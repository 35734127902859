import { Header } from "../components/Header";
import { Hero } from '../components/Hero';
import { About } from '../components/about/index.js';
import { Footer } from '../components/Footer';
import { Approach } from '../components/Approach';
import { AppRoutes } from "../Routes.js";
import { AboutHero } from "../components/about/hero.js";

function AboutPage() {
    return (
        <>
            <Header />
            {/* <AboutHero /> */}
            <About />
            {/* <Approach /> */}
            <Footer />
        </>
    );
}

export default AboutPage;
