import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import BedImg from "../../assets/images/cigna.png";
import Aetna from "../../assets/images/aetna.png";
import Bluecross from "../../assets/images/bluecross.png";
import MultiPlan from "../../assets/images/multiplan.png";
import HealthPlan from "../../assets/images/healthplan.png";
import Com from "../../assets/images/com.png";
import HomeImg from "../../assets/images/home.png";



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';


// import required modules
import { Link, useNavigate } from 'react-router-dom';


const Networks = ({ items, slidesPerPage }) => {
    const navigate = useNavigate();





    const products = [
        {
            title: 'Elementary School Math',
            img: BedImg,
            desc: 'Our elementary school math program focuses on building a strong foundation in mathematical concepts. We use interactive methods to engage young learners and develop their problem-solving skills'
        },
        {
            title: 'Middle School Math',
            img: Aetna,
            desc: 'In our middle school math program, we explore advanced topics and encourage students to think critically. We provide personalized guidance to help students excel in algebra, geometry, and more.'
        },
        {
            title: 'High School Math',
            img: Bluecross,
            desc: 'Our high school math program covers a wide range of topics, including calculus, statistics, and trigonometry. We prepare students for college-level math and help them achieve academic success.'
        },
        {
            title: 'Test Prep',
            img: MultiPlan,
            desc: 'Our test prep program equips students with the strategies and knowledge needed to excel in standardized tests such as the SAT and ACT. We provide comprehensive study materials and practice tests.'
        },
        {
            title: 'Test Prep',
            img: HealthPlan,
            desc: 'Our test prep program equips students with the strategies and knowledge needed to excel in standardized tests such as the SAT and ACT. We provide comprehensive study materials and practice tests.'
        },
        {
            title: 'Test Prep',
            img: Com,
            desc: 'Our test prep program equips students with the strategies and knowledge needed to excel in standardized tests such as the SAT and ACT. We provide comprehensive study materials and practice tests.'
        },
        // {
        //     title: 'Test Prep',
        //     img: BedImg,
        //     desc: 'Our test prep program equips students with the strategies and knowledge needed to excel in standardized tests such as the SAT and ACT. We provide comprehensive study materials and practice tests.'
        // }
    ]
    return (
        <>
            <div className="relative top-20 pb-20 mx-auto w-full overflow-hidden mx-auto w-full bg-[#fff]   pt-0 mb-0  text-[#292D32] h-full relative top-[0px] pt-24 md:pt-32">
                <h2 className="text-2xl md:text-2xl font-semibold text-[#292D32] text-center lg:text-center my-4">
                    Our Networks
                </h2>
                <p className="my-6 mt-2  mb-10 text-md lg:w-[100%] text-[#292D32] text-center lg:text-center font-[500]">
                    Our Recovery Center is connected and supported by over 1000+ companies
                </p>

                <div className='md:w-[80%] mx-auto'>
                    <Swiper
                        slidesPerView={slidesPerPage}
                        spaceBetween={30}
                        navigation={true}

                        breakpoints={{
                            0: {
                                slidesPerView: 1.2,
                            },
                            640: {
                                slidesPerView: 5,
                            }
                        }}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        <div className="holder grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                            {

                                products.length ?

                                    products.map((product, index) => <SwiperSlide key={index}>
                                        <div key={index} className="bg-[#F3F3F3] rounded-2xl">

                                            <div>

                                                <img src={product.img} alt={product.img} className='max-h-[200px] w-full object-cover' />
                                            </div>

                                        </div>
                                    </SwiperSlide>)

                                    : <></>}
                        </div>
                    </Swiper>
                </div>
            </div>
        </>
    );
}


export default Networks