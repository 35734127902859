import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import BedImg from "../../assets/images/cigna.png";
import Aetna from "../../assets/images/aetna.png";
import Bluecross from "../../assets/images/bluecross.png";
import MultiPlan from "../../assets/images/multiplan.png";
import HealthPlan from "../../assets/images/healthplan.png";
import Com from "../../assets/images/com.png";
import HomeImg from "../../assets/images/home.png";
import Clinician from "../../assets/images/clinician.svg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import Team from "../../assets/images/team.svg";




// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';


// import required modules
import { Link, useNavigate } from 'react-router-dom';


const Expertise = ({ items, slidesPerPage }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-col md:flex-row pt-24 md:pt-40 bg-[#F5F5F5]" >

                <div className='md:w-1/2'>

                    <img src={Clinician} alt='n' className='w-full' />
                </div>

                <div className='sm:pt-20 lg:pt-12 p-4 md:px-0 md:ml-10 md:w-1/2 mb-0 md:mb-0'>
                    <h2 className="text-2xl mt-3 font-[700] text-[#262626] mb-1">
                        Our Expertise
                    </h2>

                    <div className='grid grid-cols-2 lg:grid-cols-3 mt-10 w-full gap-y-4'>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Addiction</h5>
                        </div>

                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Bipolar Disorder</h5>
                        </div>
                        <div className='hidden lg:block'></div>

                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Divorce</h5>
                        </div>

                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Alcohol Use</h5>
                        </div>
                        <div className='hidden lg:block'></div>


                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Infidelity</h5>
                        </div>

                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Anger Management</h5>
                        </div>
                        <div className='hidden lg:block'></div>


                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">LGBTQ+</h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Coping Skills</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">ADHD</h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Antisocial Personality</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Grief</h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Behavioral Issues </h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Drug Abuse</h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Borderline Personality(BPD)</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Family Conflict</h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Personal Disorders</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Marital & Premarital </h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Relationship Issues</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Transgender </h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Trauma & PTSD</h5>
                        </div>
                        <div className='hidden lg:block'></div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Anxiety </h5>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={ArrowRight} alt="elipses" className="w-[20px]" />
                            <h5 className="text-md font-[400] text-center text-[#292D32]">Depression</h5>
                        </div>
                        <div className='hidden lg:block'></div>


                    </div>
                </div>


            </div>

            <div className='sm:pt-20  p-4 md:px-0  md:mb-0' id='services'>
                <h2 className="text-2xl mt-3 font-[700] text-[#262626] mb-1 text-center">
                    Our Amazing Team
                </h2>

                <img src={Team} alt='n' className='md:w-[60%] mx-auto my-10 md:my-20' />

            </div>
        </>
    );
}


export default Expertise