'use client'

import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function MobileMenu() {
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    const trigger = useRef(null)
    const mobileNav = useRef(null)
    // const imageUploadRef = useRef<HTMLImageElement>(null);


    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false)
        };
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false)
        };
        document.addEventListener('keydown', keyHandler)
        return () => document.removeEventListener('keydown', keyHandler)
    })

    return (
        <div className="md:hidden">
            {/* Hamburger button */}
            <button
                ref={trigger}
                className={`hamburger ${mobileNavOpen && 'active'}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
                <span className="sr-only">Menu</span>
                <svg
                    className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect y="4" width="24" height="2" rx="1" />
                    <rect y="11" width="24" height="2" rx="1" />
                    <rect y="18" width="24" height="2" rx="1" />
                </svg>
            </button>

            {/*Mobile navigation */}
            <nav
                id="mobile-nav"
                ref={mobileNav}
                className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out h-max"
                style={mobileNavOpen ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0.8 }}
            >
                <ul className="bg-[#fff] px-0 py-2 h-max w-[70%] ml-auto shadow-lg">

                    <li className='py-3 px-4 border-b border-[#29ABE2]'>
                        <Link to="/about" className="w-full btn-sm bg-orange-gradient rounded-xl text-sm text-[#010617] font-[600] mt-3">
                            About Us
                        </Link>
                    </li>


                    <li className='py-3 px-4 border-b border-[#29ABE2]'>
                        <Link to="/services" className="w-full btn-sm bg-orange-gradient rounded-xl text-sm text-[#010617] font-[600] mt-3">
                            Services
                        </Link>
                    </li>
                    <li className='py-3 px-4 border-b border-[#29ABE2]'>
                        <Link to="/clinicians" className="w-full btn-sm bg-orange-gradient rounded-xl text-sm text-[#010617] font-[600] mt-3">
                            Clinicians
                        </Link>
                    </li>
                    <li className='py-3 px-4 border-b border-[#29ABE2]'>
                        <Link to="/insurance" className="w-full btn-sm bg-orange-gradient rounded-xl text-sm text-[#010617] font-[600] mt-3">
                            Insurance Accepted
                        </Link>
                    </li>
                    <li className='py-3 px-4 border-b border-[#29ABE2]'>
                    <a href="/contact" className="text-sm font-[500] text-[#262626]">Contact</a>
                    </li>


                </ul>
            </nav>
        </div>
    )
}
