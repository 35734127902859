import { Header } from "../components/Header";
import { Hero } from '../components/insurance/Hero.js';
import { About } from '../components/About';
import { Footer } from '../components/Footer';
import { Approach } from '../components/Approach';
import { AppRoutes } from "../Routes.js";
import Networks from "../components/insurance/Networks.js";

function Insurance() {
    return (
        <>
            <Header />
            {/* <Hero /> */}
            <Networks />

            <div className="bg-[#29ABE2]">

                <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5 md:pt-10 md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-1">
                            Finances

                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-20 items-center">
                    <div className=" text-white  text-center py-5">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">$130</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Individual Session</p>
                    </div>
                    <div className=" text-white rounded-2xl text-center">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">$150</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Couple’s Session</p>
                    </div>
                    <div className=" text-white rounded-2xl text-center">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">Sliding Scale</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Apply if you may be eligible</p>
                    </div>





                </div>
            </div>
            {/* <Approach /> */}
            <Footer />
        </>
    );
}

export default Insurance;
