import Logo from "../assets/images/app-logo.svg";
import Call from "../assets/images/call-outgoing.svg";
import Payments from "../assets/images/payment-methods.svg";
import Mail from "../assets/images/mark_email_unread.svg";


export const Footer = () => {
    return (

        <footer className="py-8 md:py-0 bg-[#CFF1FF]">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 md:py-10">
                <div className="grid md:grid-cols-6 items-center gap-4">




                    <div className="py-0 ">
                        <div className="text-teal-600 lg:block">
                            <div className="block text-teal-600 w-24">
                                <img src={Logo} alt="Footer" className="w-[50px] md:w-[80px]" />
                            </div>
                        </div>
                    </div>



                    <div className="py-0  ">

                        <div className=" md:w-fit mx-auto">


                            <div>
                                <a href="#0" className="font-medium text-sm text-[#262626] font-semibold">
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="py-0  ">

                        <div className=" md:w-fit mx-auto">


                            <div>
                                <a href="#0" className="font-medium text-sm text-[#262626] font-semibold">
                                    Terms and Conditions
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="py-0  ">

                        <div className=" md:w-fit mx-auto">


                            <div>
                                <a href="#0" className="font-medium text-sm text-[#262626] font-semibold">
                                    FAQ
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="py-0  ">

                        <div className=" md:w-fit mx-auto">


                            <div>

                                <a href="/contact" className="font-medium text-sm text-[#262626] font-semibold">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>



                    <div className="py-0  ">

                        <div className=" md:w-fit mx-auto">


                            <div>
                                <p className="font-medium text-[#262626] text-sm font-semibold">Payment Methods</p>
                                <li className="flex items-center gap-2 mt-3">
                                    <img src={Payments} alt="call" />
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}