import { Link } from "react-router-dom";
import AboutImg from "../assets/images/mother.png";
import Elipses from "../assets/images/star.svg";
import Mission from "../assets/images/man.png";
import Union from "../assets/images/Union.png";
import MissionIcon from "../assets/images/mission-icon.svg";
import PurposeIcon from "../assets/images/purpose.svg";
import VisionIcon from "../assets/images/vision.svg";
import PlayIcon from "../assets/images/play.svg";
import Adolescent from "../assets/images/adolescent.svg";
import Adult from "../assets/adult.svg";
import Telemed from "../assets/images/telemed.svg";

export const About = () => {
    return (
        <>
            <div className="bg-[#fff] relative top-[0px]" id="about">
                <div className="mx-auto w-full max-w-screen-xl flex   md:flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-1/2 py-10 px-4">
                        <div className="">
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-3">
                                About Us

                            </h2>
                        </div>

                        <p className="md:my-8 text-md lg:w-[80%] text-[#262626] text-center font-[500] lg:text-left">
                            Our Recovery Center is the ultimate destination for individuals seeking effective and personalized care to overcome mental health and substance abuse challenges. With proven track record of success, our evidence-based treatment and compassionate support provide clients with the tools and resources they need to achieve long-term recovery and positive life changes. At Our Recovery Center, we are committed to helping every individual reach their full potential and live a happy and fulfilling life free from addiction and mental health challenges.                           </p>
                        <div className="mt-5 md:mt-0 flex items-center justify-center lg:justify-start gap-5">
                            <a className="bg-[#29ABE2] rounded-3xl px-8 py-3.5 text-white text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Learn More</a>

                        </div>

                    </div>

                    <div className="hidden md:block w-full md:w-1/2  relative">

                        <div className="md:py-0">

                            <img src={AboutImg} alt="Hero" className="lg:w-[100%] relative h-full" />
                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </div>

            <div className="bg-[#fff]">

                <div id="services" className="bg-[#fff] mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5 md:pt-20 md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-center mb-1">
                            Why You Should Choose Us

                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-20">
                    <div className="bg-[#F3F3F3]  rounded-2xl p-5 py-10 text-center">
                        <img src={MissionIcon} alt="elipses" className="w-[15%] mx-auto" />
                        <h5 className="text-xl font-semibold mt-2 mb-3">Mission</h5>
                        <p className="my-2 text-sm  text-[#292D32] text-center font-[500]">Our Recovery Centers offers evidence-based treatments and compassionate support, empowering individuals to overcome addiction and mental health challenges and achieve lasting positive change for a better quality of life.</p>
                    </div>
                    <div className="bg-[#F3F3F3]  rounded-2xl p-5 py-10 text-center">
                        <img src={PurposeIcon} alt="elipses" className="w-[15%] mx-auto" />
                        <h5 className="text-xl font-semibold mt-2 mb-5">Purpose</h5>
                        <p className="my-2 text-sm  text-[#292D32] text-center font-[500]">Our Program is designed to provide individual care and comprehensive treatment services to clients who are struggling with mental health issues. Our goal is to offer high-quality support and treatment to empower our clients to overcome their challenges and achieve lasting success.</p>
                    </div>
                    <div className="bg-[#F3F3F3]  rounded-2xl p-5 py-10 text-center" >
                        <img src={VisionIcon} alt="elipses" className="w-[15%] mx-auto" />
                        <h5 className="text-xl font-semibold mt-2 mb-5">Vision</h5>
                        <p className="my-2 text-sm  text-[#292D32] text-center font-[500]" id="ourservices">Our Vision is to offer clients exceptional and personalized care through comprehensive treatment services. We strive to empower our clients to become leaders of their community, fostering productivity and innovation in their  personal and professional lives.</p>
                    </div>




                </div>
            </div>
            <div className="bg-[#29ABE2] mt-10 pb-20">

                <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5  md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-1">
                            Our Services
                        </h2>
                        <p className="text-md text-[#fff] text-center font-[500] lg:text-center">
                            Call in today for our expert guidance services
                        </p>


                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-10 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-10">
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Adolescent} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Children/Adolescent</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Bipolar & other mood disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Obsessive compulsive disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Attention & Focus Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Behavioural Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Anxiety Disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Depression</p>
                        </div>
                    </div>
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Adult} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Adult, Group/Family</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Bipolar & other mood disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Obsessive compulsive disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Attention & Focus Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Substance Use Disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Probation Client</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Anxiety Disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Depression</p>
                        </div>
                    </div>
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Telemed} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Telemedicine</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Telephone Visits if permissible</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Secure and HIPPA Compliant</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Electronic Prescription</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Video Consultation</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Satellite Clinics</p>
                        </div>
                    </div>

                </div>


                <div className="text-center mt-5">
                    <a className="bg-[#fff] rounded-[16px] px-8 py-4 text-[#29ABE2] text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Book Appointment</a>

                </div>
            </div>

            <section className="hidden bg-[#fff]">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div className="py-5 md:py-10">
                        <img src={Mission} alt="elipses" className="md:w-[80%] h-full object-fit-cover rounded-2xl" />

                    </div>

                    <div>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                            Smart Devices For

                        </h2>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                            Video Chat

                        </h2>

                        <div className="md:w-[70%]">
                            <div className="bg-white py-4 rounded-xl">
                                <p className="my-2 text-sm lg:w-1080%] text-[#292D32] text-center md:text-left  font-[500]">At wonderful life care home, we embrace innovative technology to bridge the gap between our senior residents and their loved ones. Our facility is equipped with cutting-edge smart devices that facilitate effortless video chatting, enabling our seniors to stay connected with family members no matter the distance.</p>
                            </div>
                        </div>
                    </div>




                </div>
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                            Mission & Vision
                        </h2>

                        <div className="md:w-[70%]">
                            <div className="bg-white py-4 rounded-xl mb-4">
                                <p className="my-2 text-sm lg:w-1080%] text-[#292D32] text-center md:text-left  font-[500]">At wonderful life care homes, our philosophy is centered around creating a better tomorrow through exceptional care and unwavering services quality within our residential setting. Our ethics is to give our clients a home away from home, an abode of warmth, familiarity, and comfort. We see every member as family, fostering a positive and uplifting atmosphere for all. Our comprehensive array of services. including three nutritious meals with snacks daily, round-the-clock monitoring by trained staff, holistic therapy, medication management, and transportation for medical visits, ensures that every aspect of our residents' needs are net with meticulous attention.</p>
                            </div>

                            <a className="bg-[#640086] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500] w-fit mx-auto block md:inline" href="mailto:info@ourrecoverycenters.com">Contact Us</a>

                        </div>
                    </div>

                    <div className="py-5 md:py-10">
                        <img src={Union} alt="elipses" className="md:w-[80%] h-full object-fit-cover rounded-2xl" />

                    </div>



                </div>
            </section>
        </>
    )
}