import { Header } from "../components/Header";
import { Hero } from '../components/Hero';
import { About } from '../components/about/index.js';
import { Footer } from '../components/Footer';
import { Approach } from '../components/Approach';
import { AppRoutes } from "../Routes.js";
import { AboutHero } from "../components/about/hero.js";
import Adolescent from "../assets/images/adolescent.svg";
import PlayIcon from "../assets/images/play.svg";
import Adult from "../assets/adult.svg";
import Telemed from "../assets/images/telemed.svg";


function ServicesPage() {
    return (
        <>
            <Header />
            <div className="bg-[#29ABE2] mt-10 py-20">

                <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5  md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-1">
                            Our Services
                        </h2>
                        <p className="text-md text-[#fff] text-center font-[500] lg:text-center">
                            Call in today for our expert guidance services
                        </p>


                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-10 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-10">
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Adolescent} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Children/Adolescent</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Bipolar & other mood disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Obsessive compulsive disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Attention & Focus Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Behavioural Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Anxiety Disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Depression</p>
                        </div>
                    </div>
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Adult} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Adult, Group/Family</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Bipolar & other mood disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Obsessive compulsive disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Attention & Focus Issues</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Substance Use Disorder</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Probation Client</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Anxiety Disorders</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Depression</p>
                        </div>
                    </div>
                    <div className="bg-[#DCDCDC4D]  rounded-2xl p-5 py-10 text-white  border border-white">
                        <img src={Telemed} alt="elipses" className="w-[70%] mx-auto my-10" />
                        <h5 className="text-2xl font-semibold mt-2 mb-5 text-center">Telemedicine</h5>

                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Telephone Visits if permissible</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Secure and HIPPA Compliant</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Electronic Prescription</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Video Consultation</p>
                        </div>
                        <div className="flex gap-3 text-white mb-3">
                            <img src={PlayIcon} alt="elipses" className="w-[5%]" />
                            <p className="text-md  text-[#fff] text-center font-[400]">Satellite Clinics</p>
                        </div>
                    </div>

                </div>


                <div className="text-center mt-5">
                    <a className="bg-[#fff] rounded-[16px] px-8 py-4 text-[#29ABE2] text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Book Appointment</a>

                </div>
            </div>
            {/* <Approach /> */}
            <Footer />
        </>
    );
}

export default ServicesPage;
