import React, { useEffect } from "react";

import AppRoutes from "./Routes.js";
import './App.css';



function App() {


  return (
    <>
      <AppRoutes />

    </>
  );
}

export default App;
