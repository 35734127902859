import { Link } from "react-router-dom";
import AboutImg from "../../assets/images/story.svg";
import Mission from "../../assets/images/man.png";
import Union from "../../assets/images/Union.png";
import MissionIcon from "../../assets/images/mission-icon.svg";
import PurposeIcon from "../../assets/images/purpose.svg";
import VisionIcon from "../../assets/images/vision.svg";
import Hand from "../../assets/images/hand.svg";
import Compassion from "../../assets/images/compassion.svg";
import Integrity from "../../assets/images/integrity.svg";
import Education from "../../assets/images/education.svg";
import Ethics from "../../assets/images/ethics.svg";
import Independence from "../../assets/images/independence.svg";
import Accountability from "../../assets/images/accountability.svg";
import Adolescent from "../../assets/images/adolescent.svg";

export const About = () => {
    return (
        <>
            <div className="bg-[#fff] relative top-10 md:top-24" id="about">
                <div className="mx-auto w-full max-w-screen-xl flex   md:flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-1/2  px-4 py-12">
                        <div className="pt-5">
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-3">
                                Our Story

                            </h2>
                        </div>

                        <p className="md:my-3 text-md lg:w-[80%] text-[#262626] text-md text-center font-[500] lg:text-left">
                            At our Recovery Center, we believe that everyone deserves a second chance at life. We understand that mental health and substance abuse challenges can be overwhelming and isolating, but we know that recovery is possible with the right support and resources. That’s why we have created a safe and nurturing environment where individuals can heal, grow, thrive. Our approach is rooted in evidence-based treatments and personalized care. We don’t believe in a one-size-fits-all approach, as we know that every individuals journey to recovery is unique. our team of highly killed professionals is passionate about helping clients overcome their challenges and achieve their goals. We provide compassionate support guidance, and expertise every step of the way.     </p>
                        <div className="mt-5 md:mt-0 flex items-center justify-center lg:justify-start gap-5">
                            <a className="hidden bg-[#29ABE2] rounded-3xl px-8 py-3.5 text-white text-sm font-[500]" href="mailto:info@ourrecoverycenters.com">Learn More</a>

                        </div>

                    </div>

                    <div className="hidden md:block w-full md:w-1/2  relative">

                        <div className="md:py-0">

                            <img src={AboutImg} alt="Hero" className="lg:w-[100%] relative h-full" />
                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </div>

            <div className="bg-[#29ABE2] mt-2 pt-10 md:pt-20 md:mt-0">

                <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5 md:pt-10 md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-1">
                            Numbers Speak
                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-20 items-center">
                    <div className=" text-white  text-center py-5">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">400+</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Satisfied Clients</p>
                    </div>
                    <div className=" text-white rounded-2xl text-center">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">157+</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Consultations</p>
                    </div>
                    <div className=" text-white rounded-2xl text-center">

                        <h5 className="text-3xl font-semibold mt-2 mb-3">1</h5>
                        <p className="my-2 text-xl  text-[#fff] text-center font-[500]">Professional</p>
                    </div>





                </div>
            </div>

            <div className="bg-[#fff]">

                <div id="services" className="bg-[#fff] mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5 md:pt-20 md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-center mb-5">
                            Our Core Values
                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">


                    <img src={Hand} alt="han" />
                    <img src={Compassion} alt="han" />
                    <img src={Integrity} alt="han" />
                    <img src={Education} alt="han" />


                </div>
                <div className="mx-auto w-full max-w-screen-xl px-4 mb-5 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <img src={Ethics} alt="han" />
                    <img src={Independence} alt="han" />
                    <img src={Accountability} alt="han" />

                </div>
            </div>
        </>
    )
}