import { Header } from "../components/Header";
import { Footer } from '../components/Footer';
import { useEffect, useState } from "react";
import emailjs from '@emailjs/browser';



function ContactPage() {
    const [contactForm, setContactForm] = useState(Object)
    const [nameError, setNameError] = useState(false)
    const [isDisabled, setIsdisabled] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [messageError, setMessageError] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState(false)

    const handleFormSubmit = () => {
        setIsdisabled(true)
        setNameError(false)
        setEmailError(false)
        setMessageError(false)

        if (!contactForm.name) {
            setNameError(true)
        }
        if (!contactForm.email) {
            setEmailError(true)
        }
        if (!contactForm.message) {
            setMessageError(true)
        }
        if (contactForm.name && contactForm.email && contactForm.message) {
            // ServiceId and templateId defined and assigned
            const serviceID = "service_0x34jtb";
            const templateID = "template_odv94pe";
            let tempParams = {
                user_email: contactForm.email,
                user_name: contactForm.name,
                user_message: contactForm.message,
                company_name: 'Our Recovery Center',
            };

            try {

                emailjs.send(serviceID, templateID, tempParams).then(
                    (res) => {
                        setMessageSuccess(true)
                        setNameError(false)
                        setEmailError(false)
                        setMessageError(false)
                        setContactForm({
                            name: '',
                            email: '',
                            message: ''
                        })
                        setIsdisabled(false)
                        setTimeout(() => {
                            setMessageSuccess(false)
                        }, 3000);
                    },

                    // catch error
                    (err) => {
                        // set default
                        alert(JSON.stringify(err));
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
        else {
            setIsdisabled(false)
        }
    }

    useEffect(() => {
        emailjs.init({
            publicKey: 'user_a9mZBWvXDzDm7HUPXZj4q',
        });
    }, [])
    return (
        <>
            <Header />
            <div className="bg-[#29ABE2] pt-24" id="contact">

                <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full md:py-5 md:pt-10 md:pb-5  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#fff] text-center lg:text-center mb-1">
                            Contact Us

                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2  gap-10 lg:gap-20 items-start">
                    <div className=" text-white">

                        <h5 className="text-xl font-semibold mt-2 mb-3">Please Reach Out</h5>

                        <form className="block md:w-[90%] mt-5">
                            <input type="text" value={contactForm.name} onFocus={() => setNameError(false)} onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })} className="bg-white h-[45px]  w-full p-2 outline-none border-none text-black text-sm" placeholder="Your Name" />
                            {nameError && <span className="text-sm text-red-600">Name is required</span>}
                            <input type="email" value={contactForm.email} onFocus={() => setEmailError(false)} onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} className="bg-white h-[45px] mt-4 w-full p-2 outline-none border-none text-black text-sm" placeholder="Email Address" />
                            {emailError && <span className="text-sm text-red-600">Email is required</span>}

                            <textarea value={contactForm.message} onFocus={() => setMessageError(false)} placeholder="Your Message" onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })} className="w-full h-[100px] p-2 mt-4 outline-none border-none text-black text-sm" />
                            {messageError && <span className="text-sm text-red-600">Message is required</span>}

                            <button disabled={isDisabled} type="button" onClick={handleFormSubmit} className="disabled:opacity-[0.7] disabled:cursor-not-allowed block mt-2 w-fit mr-auto py-3 px-8 bg-white rounded-xl text-[#29ABE2] uppercase text-sm font-semibold">Send message</button>
                            {messageSuccess && <span>Your message has been sent successfully.</span>}
                        </form>
                    </div>
                    <section>
                        <div className=" text-white rounded-2xl text-left">

                            <h5 className="text-xl font-semibold mt-2 mb-3">Contact Info</h5>
                            <div className="my-2">
                                <p className="my-2 text-md  text-[#fff] text-left font-[500]">Address</p>
                                <p className="my-2 text-sm  text-[#fff] text-left font-[400]">7457, Harwin Dr. Suite 310, Houston, Texas,USA</p>
                            </div>
                            <div className="my-5">
                                <p className="my-2 text-md  text-[#fff] text-left font-[500]">Email Us</p>
                                <p className="my-2 text-sm  text-[#fff] text-left font-[400]">                            <a className="" href="mailto:info@ourrecoverycenters.com">info@ourrecoverycenters.com</a>
                                </p>
                            </div>
                            <div className="my-5">
                                <p className="my-2 text-md  text-[#fff] text-left font-[500]">Call Us</p>
                                <p className="my-2 text-sm  text-[#fff] text-left font-[400]"> 713-393-7804
                                </p>
                            </div>
                            <div className="my-5">
                                <p className="my-2 text-md  text-[#fff] text-left font-[500]">Follow Us</p>
                                <div className="flex items-center gap-2">
                                    <p className="my-1 text-sm  text-[#fff] text-left font-[400]"> <a href="#0"><svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="-0.00976562" width="38.39" height="38.39" rx="19.195" fill="#3B5998" />
                                        <path d="M23.0649 20.1802H20.7212V27.1802H17.5962V20.1802H15.0337V17.3052H17.5962V15.0864C17.5962 12.5864 19.0962 11.1802 21.3774 11.1802C22.4712 11.1802 23.6274 11.3989 23.6274 11.3989V13.8677H22.3462C21.0962 13.8677 20.7212 14.6177 20.7212 15.4302V17.3052H23.5024L23.0649 20.1802Z" fill="white" />
                                    </svg></a>
                                    </p>
                                    <p className="my-1 text-sm  text-[#fff] text-left font-[400]"> <a href="#0">
                                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.389893" y="-0.00976562" width="38.39" height="38.39" rx="19.195" fill="#262626" />
                                            <path d="M19.7611 15.5864C21.7298 15.5864 23.3548 17.2114 23.3548 19.1802C23.3548 21.1802 21.7298 22.7739 19.7611 22.7739C17.7611 22.7739 16.1673 21.1802 16.1673 19.1802C16.1673 17.2114 17.7611 15.5864 19.7611 15.5864ZM19.7611 21.5239C21.0423 21.5239 22.0736 20.4927 22.0736 19.1802C22.0736 17.8989 21.0423 16.8677 19.7611 16.8677C18.4486 16.8677 17.4173 17.8989 17.4173 19.1802C17.4173 20.4927 18.4798 21.5239 19.7611 21.5239ZM24.3236 15.4614C24.3236 15.9302 23.9486 16.3052 23.4798 16.3052C23.0111 16.3052 22.6361 15.9302 22.6361 15.4614C22.6361 14.9927 23.0111 14.6177 23.4798 14.6177C23.9486 14.6177 24.3236 14.9927 24.3236 15.4614ZM26.6986 16.3052C26.7611 17.4614 26.7611 20.9302 26.6986 22.0864C26.6361 23.2114 26.3861 24.1802 25.5736 25.0239C24.7611 25.8364 23.7611 26.0864 22.6361 26.1489C21.4798 26.2114 18.0111 26.2114 16.8548 26.1489C15.7298 26.0864 14.7611 25.8364 13.9173 25.0239C13.1048 24.1802 12.8548 23.2114 12.7923 22.0864C12.7298 20.9302 12.7298 17.4614 12.7923 16.3052C12.8548 15.1802 13.1048 14.1802 13.9173 13.3677C14.7611 12.5552 15.7298 12.3052 16.8548 12.2427C18.0111 12.1802 21.4798 12.1802 22.6361 12.2427C23.7611 12.3052 24.7611 12.5552 25.5736 13.3677C26.3861 14.1802 26.6361 15.1802 26.6986 16.3052ZM25.1986 23.3052C25.5736 22.3989 25.4798 20.2114 25.4798 19.1802C25.4798 18.1802 25.5736 15.9927 25.1986 15.0552C24.9486 14.4614 24.4798 13.9614 23.8861 13.7427C22.9486 13.3677 20.7611 13.4614 19.7611 13.4614C18.7298 13.4614 16.5423 13.3677 15.6361 13.7427C15.0111 13.9927 14.5423 14.4614 14.2923 15.0552C13.9173 15.9927 14.0111 18.1802 14.0111 19.1802C14.0111 20.2114 13.9173 22.3989 14.2923 23.3052C14.5423 23.9302 15.0111 24.3989 15.6361 24.6489C16.5423 25.0239 18.7298 24.9302 19.7611 24.9302C20.7611 24.9302 22.9486 25.0239 23.8861 24.6489C24.4798 24.3989 24.9798 23.9302 25.1986 23.3052Z" fill="white" />
                                        </svg>

                                    </a>
                                    </p>
                                    <p className="my-1 text-sm  text-[#fff] text-left font-[400]"> <a href="#0">
                                        <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.780029" y="-0.00976562" width="38.39" height="38.39" rx="19.195" fill="#0077B5" />
                                            <path d="M26.1512 12.1802C26.6825 12.1802 27.1512 12.6489 27.1512 13.2114V25.1802C27.1512 25.7427 26.6825 26.1802 26.1512 26.1802H14.12C13.5887 26.1802 13.1512 25.7427 13.1512 25.1802V13.2114C13.1512 12.6489 13.5887 12.1802 14.12 12.1802H26.1512ZM17.37 24.1802V17.5239H15.3075V24.1802H17.37ZM16.3387 16.5864C16.995 16.5864 17.5262 16.0552 17.5262 15.3989C17.5262 14.7427 16.995 14.1802 16.3387 14.1802C15.6512 14.1802 15.12 14.7427 15.12 15.3989C15.12 16.0552 15.6512 16.5864 16.3387 16.5864ZM25.1512 24.1802V20.5239C25.1512 18.7427 24.745 17.3364 22.6512 17.3364C21.6512 17.3364 20.9637 17.8989 20.6825 18.4302H20.6512V17.5239H18.6825V24.1802H20.745V20.8989C20.745 20.0239 20.9012 19.1802 21.995 19.1802C23.0575 19.1802 23.0575 20.1802 23.0575 20.9302V24.1802H25.1512Z" fill="white" />
                                        </svg>


                                    </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </section>
                    <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js"></script>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactPage;
