import { Header } from "../components/Header";
import { Hero } from '../components/Hero';
import { About } from '../components/About';
import { Footer } from '../components/Footer';
import { Approach } from '../components/Approach';
import { AppRoutes } from "../Routes.js";
import {  Switch, useLocation } from 'react-router-dom'
import { useEffect } from "react";


function Home() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Approach />
      <Footer />
    </>
  );
}

export default Home;
